import { SearchOutlined } from "@ant-design/icons";
import { Input, Space } from "antd";
import React from "react";
import Button from "../button";
import styles from "./searchBar.module.scss";

interface SearchInputProps {
  borderColor?: string; // Border color of the input
  placeholder?: string;
  searchValue: string;
  setSearchValue: (value: string) => void;
  onSearch: (value: string) => void;
}

const SearchBar = ({
  placeholder,
  searchValue,
  setSearchValue,
  onSearch,
}: SearchInputProps) => {
  const handleSearch = () => {
    onSearch(searchValue);
  };

  return (
    <Space.Compact style={{ width: "100%", margin: 0 }}>
      <Input
        value={searchValue}
        onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
          setSearchValue(e.target.value)
        }
        placeholder={placeholder}
        autoFocus
        onPressEnter={handleSearch}
        className={styles.search_input}
      />
      <Button icon={<SearchOutlined />} onBtnClick={handleSearch} />
    </Space.Compact>
  );
};

export default SearchBar;
